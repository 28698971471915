
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorFrontOrderNae, SelectorFrontOrderLogNae, SelectorPaypalDisputeNae, SelectorPaypalTransactionNae, SelectorPaypalWebhookNae, SelectorRecurringNae, SelectorRefundRequestNae, SelectorSystemRunNae, SelectorWebhookNae } from '../models/ormSelectors';
import { useFrontOrderHookNae } from '../hooks/useFrontOrderHookNae';
import { useFrontOrderLogHookNae } from '../hooks/useFrontOrderLogHookNae';
import { usePaypalDisputeHookNae } from '../hooks/usePaypalDisputeHookNae';
import { usePaypalTransactionHookNae } from '../hooks/usePaypalTransactionHookNae';
import { usePaypalWebhookHookNae } from '../hooks/usePaypalWebhookHookNae';
import { useRecurringHookNae } from '../hooks/useRecurringHookNae';
import { useRefundRequestHookNae } from '../hooks/useRefundRequestHookNae';
import { useSystemRunHookNae } from '../hooks/useSystemRunHookNae';
import { useWebhookHookNae } from '../hooks/useWebhookHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'front-order') {
    return useFrontOrderHookNae;
  }
  if (schema === 'front-order-log') {
    return useFrontOrderLogHookNae;
  }
  if (schema === 'paypal-dispute') {
    return usePaypalDisputeHookNae;
  }
  if (schema === 'paypal-transaction') {
    return usePaypalTransactionHookNae;
  }
  if (schema === 'paypal-webhook') {
    return usePaypalWebhookHookNae;
  }
  if (schema === 'recurring') {
    return useRecurringHookNae;
  }
  if (schema === 'refund-request') {
    return useRefundRequestHookNae;
  }
  if (schema === 'system-run') {
    return useSystemRunHookNae;
  }
  if (schema === 'webhook') {
    return useWebhookHookNae;
  }
  return selector;
}

export function FrontOrderEmptyFieldNae(props: PropsLink) {
  const element = useFrontOrderHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function FrontOrderLogEmptyFieldNae(props: PropsLink) {
  const element = useFrontOrderLogHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaypalDisputeEmptyFieldNae(props: PropsLink) {
  const element = usePaypalDisputeHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaypalTransactionEmptyFieldNae(props: PropsLink) {
  const element = usePaypalTransactionHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaypalWebhookEmptyFieldNae(props: PropsLink) {
  const element = usePaypalWebhookHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function RecurringEmptyFieldNae(props: PropsLink) {
  const element = useRecurringHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function RefundRequestEmptyFieldNae(props: PropsLink) {
  const element = useRefundRequestHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function SystemRunEmptyFieldNae(props: PropsLink) {
  const element = useSystemRunHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function WebhookEmptyFieldNae(props: PropsLink) {
  const element = useWebhookHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

