import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "FrontOrder",
        "schema": "front-order",
        "title": "Order",
        "titlePlural": "Orders",
        "required": [],
        "scopes": []
    },
    {
        "className": "FrontOrderLog",
        "schema": "front-order-log",
        "title": "Front order LOG",
        "titlePlural": "Front order LOGs",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaypalDispute",
        "schema": "paypal-dispute",
        "title": "Dispute",
        "titlePlural": "Disputes",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaypalTransaction",
        "schema": "paypal-transaction",
        "title": "Transaction",
        "titlePlural": "Transactions",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaypalWebhook",
        "schema": "paypal-webhook",
        "title": "Webhook",
        "titlePlural": "Webhooks",
        "required": [],
        "scopes": []
    },
    {
        "className": "Recurring",
        "schema": "recurring",
        "title": "Recurring",
        "titlePlural": "Recurrings",
        "required": [],
        "scopes": []
    },
    {
        "className": "RefundRequest",
        "schema": "refund-request",
        "title": "Refund request",
        "titlePlural": "Refund requests",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SystemRun",
        "schema": "system-run",
        "title": "System run",
        "titlePlural": "System runs",
        "required": [
            "command"
        ],
        "scopes": []
    },
    {
        "className": "User",
        "schema": "user",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Webhook",
        "schema": "webhook",
        "title": "Webhook",
        "titlePlural": "Webhooks",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "PaypalTransaction": {
        "className": "PaypalTransaction",
        "schema": "paypal-transaction"
    },
    "PaypalDispute": {
        "className": "PaypalDispute",
        "schema": "paypal-dispute"
    },
    "SystemRun": {
        "className": "SystemRun",
        "schema": "system-run"
    },
    "PaypalWebhook": {
        "className": "PaypalWebhook",
        "schema": "paypal-webhook"
    },
    "FrontOrder": {
        "className": "FrontOrder",
        "schema": "front-order"
    },
    "RefundRequest": {
        "className": "RefundRequest",
        "schema": "refund-request"
    },
    "Webhook": {
        "className": "Webhook",
        "schema": "webhook"
    },
    "FrontOrderLog": {
        "className": "FrontOrderLog",
        "schema": "front-order-log"
    },
    "Recurring": {
        "className": "Recurring",
        "schema": "recurring"
    },
    "SfKeyValueOrm": {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm"
    },
    "User": {
        "className": "User",
        "schema": "user"
    }
}