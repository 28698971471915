import { IFrontOrderFieldsNae } from './useFrontOrderHookNae';
import { IFrontOrderLogFieldsNae } from './useFrontOrderLogHookNae';
import { IPaypalDisputeFieldsNae } from './usePaypalDisputeHookNae';
import { IPaypalTransactionFieldsNae } from './usePaypalTransactionHookNae';
import { IPaypalWebhookFieldsNae } from './usePaypalWebhookHookNae';
import { IRecurringFieldsNae } from './useRecurringHookNae';
import { IRefundRequestFieldsNae } from './useRefundRequestHookNae';
import { ISystemRunFieldsNae } from './useSystemRunHookNae';
import { IWebhookFieldsNae } from './useWebhookHookNae';
import { SelectorFrontOrderNae,SelectorFrontOrderLogNae,SelectorPaypalDisputeNae,SelectorPaypalTransactionNae,SelectorPaypalWebhookNae,SelectorRecurringNae,SelectorRefundRequestNae,SelectorSystemRunNae,SelectorWebhookNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.FrontOrder.className) {
            return {
                dataToCheck: SelectorFrontOrderNae(store.getState()),
                fields: IFrontOrderFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.FrontOrderLog.className) {
            return {
                dataToCheck: SelectorFrontOrderLogNae(store.getState()),
                fields: IFrontOrderLogFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PaypalDispute.className) {
            return {
                dataToCheck: SelectorPaypalDisputeNae(store.getState()),
                fields: IPaypalDisputeFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PaypalTransaction.className) {
            return {
                dataToCheck: SelectorPaypalTransactionNae(store.getState()),
                fields: IPaypalTransactionFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PaypalWebhook.className) {
            return {
                dataToCheck: SelectorPaypalWebhookNae(store.getState()),
                fields: IPaypalWebhookFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Recurring.className) {
            return {
                dataToCheck: SelectorRecurringNae(store.getState()),
                fields: IRecurringFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.RefundRequest.className) {
            return {
                dataToCheck: SelectorRefundRequestNae(store.getState()),
                fields: IRefundRequestFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.SystemRun.className) {
            return {
                dataToCheck: SelectorSystemRunNae(store.getState()),
                fields: ISystemRunFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Webhook.className) {
            return {
                dataToCheck: SelectorWebhookNae(store.getState()),
                fields: IWebhookFieldsNae,    
            }
        }
        return undefined;
}