import { ORM } from "redux-orm";
import FrontOrderModel from "./FrontOrderModel"
import FrontOrderLogModel from "./FrontOrderLogModel"
import PaypalDisputeModel from "./PaypalDisputeModel"
import PaypalTransactionModel from "./PaypalTransactionModel"
import PaypalWebhookModel from "./PaypalWebhookModel"
import QueueModel from "./QueueModel"
import RecurringModel from "./RecurringModel"
import RefundRequestModel from "./RefundRequestModel"
import SystemRunModel from "./SystemRunModel"
import WebhookModel from "./WebhookModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    FrontOrderModel,
    FrontOrderLogModel,
    PaypalDisputeModel,
    PaypalTransactionModel,
    PaypalWebhookModel,
    QueueModel,
    RecurringModel,
    RefundRequestModel,
    SystemRunModel,
    WebhookModel,
);

export default orm;