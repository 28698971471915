import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorFrontOrderNae = createSelector(orm.FrontOrderModel);
export const SelectorFrontOrderLogNae = createSelector(orm.FrontOrderLogModel);
export const SelectorPaypalDisputeNae = createSelector(orm.PaypalDisputeModel);
export const SelectorPaypalTransactionNae = createSelector(orm.PaypalTransactionModel);
export const SelectorPaypalWebhookNae = createSelector(orm.PaypalWebhookModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorRecurringNae = createSelector(orm.RecurringModel);
export const SelectorRefundRequestNae = createSelector(orm.RefundRequestModel);
export const SelectorSystemRunNae = createSelector(orm.SystemRunModel);
export const SelectorWebhookNae = createSelector(orm.WebhookModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'FrontOrder') {
        return SelectorFrontOrderNae;
    }
        if (schema === 'FrontOrderLog') {
        return SelectorFrontOrderLogNae;
    }
        if (schema === 'PaypalDispute') {
        return SelectorPaypalDisputeNae;
    }
        if (schema === 'PaypalTransaction') {
        return SelectorPaypalTransactionNae;
    }
        if (schema === 'PaypalWebhook') {
        return SelectorPaypalWebhookNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'Recurring') {
        return SelectorRecurringNae;
    }
        if (schema === 'RefundRequest') {
        return SelectorRefundRequestNae;
    }
        if (schema === 'SystemRun') {
        return SelectorSystemRunNae;
    }
        if (schema === 'Webhook') {
        return SelectorWebhookNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'front-order') {
        return SelectorFrontOrderNae;
    }
        if (schema === 'front-order-log') {
        return SelectorFrontOrderLogNae;
    }
        if (schema === 'paypal-dispute') {
        return SelectorPaypalDisputeNae;
    }
        if (schema === 'paypal-transaction') {
        return SelectorPaypalTransactionNae;
    }
        if (schema === 'paypal-webhook') {
        return SelectorPaypalWebhookNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'recurring') {
        return SelectorRecurringNae;
    }
        if (schema === 'refund-request') {
        return SelectorRefundRequestNae;
    }
        if (schema === 'system-run') {
        return SelectorSystemRunNae;
    }
        if (schema === 'webhook') {
        return SelectorWebhookNae;
    }
    }