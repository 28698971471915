import { INaeProperty } from "@newageerp/v3.app.main-bundle";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "front-order",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "front-order",
        "key": "ba",
        "type": "string",
        "format": "",
        "title": "ba",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "baCancel",
        "type": "boolean",
        "format": "",
        "title": "baCancel",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "front-order",
        "key": "baSuccess",
        "type": "boolean",
        "format": "",
        "title": "baSuccess",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "front-order",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "email",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "front-order",
        "key": "orderData",
        "type": "array",
        "format": "string",
        "title": "orderData",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "front-order",
        "key": "referenceId",
        "type": "string",
        "format": "",
        "title": "referenceId",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order",
        "key": "token",
        "type": "string",
        "format": "",
        "title": "token",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "front-order-log",
        "key": "path",
        "type": "string",
        "format": "",
        "title": "path",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "referenceId",
        "type": "string",
        "format": "",
        "title": "referenceId",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "front-order-log",
        "key": "request",
        "type": "array",
        "format": "string",
        "title": "request",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "front-order-log",
        "key": "response",
        "type": "array",
        "format": "string",
        "title": "response",
        "additionalProperties": [],
        "description": "",
        "className": "FrontOrderLog",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "paypal-dispute",
        "key": "createTime",
        "type": "string",
        "format": "date-time",
        "title": "createTime",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "createdAtFormatted",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeAmount",
        "type": "number",
        "format": "float",
        "title": "disputeAmount",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeAmountCurrency",
        "type": "string",
        "format": "",
        "title": "disputeAmountCurrency",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeChannel",
        "type": "string",
        "format": "",
        "title": "disputeChannel",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeId",
        "type": "string",
        "format": "",
        "title": "disputeId",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeLifeCycleStage",
        "type": "string",
        "format": "",
        "title": "disputeLifeCycleStage",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputeState",
        "type": "string",
        "format": "",
        "title": "disputeState",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputedTransactions",
        "type": "array",
        "format": "string",
        "title": "disputedTransactions",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "paypal-dispute",
        "key": "disputedTransactionsIds",
        "type": "string",
        "format": "",
        "title": "Transaction IDs",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "paypal-dispute",
        "key": "reason",
        "type": "string",
        "format": "",
        "title": "reason",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-dispute",
        "key": "updateTime",
        "type": "string",
        "format": "date-time",
        "title": "updateTime",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalDispute",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "bankRefenceId",
        "type": "string",
        "format": "",
        "title": "bankRefenceId",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "cartInfo",
        "type": "array",
        "format": "string",
        "title": "cartInfo",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "paypal-transaction",
        "key": "creditTerm",
        "type": "string",
        "format": "",
        "title": "creditTerm",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "customField",
        "type": "string",
        "format": "",
        "title": "customField",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "feeAmount",
        "type": "number",
        "format": "float",
        "title": "feeAmount",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "paypal-transaction",
        "key": "feeAmountCurrency",
        "type": "string",
        "format": "",
        "title": "feeAmountCurrency",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "paypal-transaction",
        "key": "instrumentSubType",
        "type": "string",
        "format": "",
        "title": "instrumentSubType",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "instrumentType",
        "type": "string",
        "format": "",
        "title": "instrumentType",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "invoiceId",
        "type": "string",
        "format": "",
        "title": "invoiceId",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "paypal-transaction",
        "key": "payerInfo",
        "type": "array",
        "format": "string",
        "title": "payerInfo",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "paypal-transaction",
        "key": "paymentMethodType",
        "type": "string",
        "format": "",
        "title": "paymentMethodType",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "paymentTrackingId",
        "type": "string",
        "format": "",
        "title": "paymentTrackingId",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "paypalAccountId",
        "type": "string",
        "format": "",
        "title": "paypalAccountId",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "paypalReferenceId",
        "type": "string",
        "format": "",
        "title": "paypalReferenceId",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "paypalReferenceIdType",
        "type": "string",
        "format": "",
        "title": "paypalReferenceIdType",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "protectionEligibility",
        "type": "string",
        "format": "",
        "title": "protectionEligibility",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "shippingInfo",
        "type": "array",
        "format": "string",
        "title": "shippingInfo",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionAmount",
        "type": "number",
        "format": "float",
        "title": "transactionAmount",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionAmountCurrency",
        "type": "string",
        "format": "",
        "title": "transactionAmountCurrency",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionEventCode",
        "type": "string",
        "format": "",
        "title": "transactionEventCode",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionId",
        "type": "string",
        "format": "",
        "title": "transactionId",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionInitiationDate",
        "type": "string",
        "format": "date-time",
        "title": "transactionInitiationDate",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionNote",
        "type": "string",
        "format": "",
        "title": "transactionNote",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionStatus",
        "type": "string",
        "format": "",
        "title": "transactionStatus",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionSubject",
        "type": "string",
        "format": "",
        "title": "transactionSubject",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "transactionUpdatedDate",
        "type": "string",
        "format": "date-time",
        "title": "transactionUpdatedDate",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "paypal-transaction",
        "key": "userCanceled",
        "type": "boolean",
        "format": "",
        "title": "userCanceled",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalTransaction",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "paypal-webhook",
        "key": "eventTypes",
        "type": "string",
        "format": "text",
        "title": "Events",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalWebhook",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "paypal-webhook",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalWebhook",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "paypal-webhook",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "ID",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalWebhook",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "paypal-webhook",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "Updated At",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalWebhook",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "paypal-webhook",
        "key": "url",
        "type": "string",
        "format": "text",
        "title": "Url",
        "additionalProperties": [],
        "description": "",
        "className": "PaypalWebhook",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "recurring",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "recurring",
        "key": "chargeId",
        "type": "string",
        "format": "",
        "title": "chargeId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "currency",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "error",
        "type": "string",
        "format": "text",
        "title": "error",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "recurring",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "recurring",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "orderId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "piId",
        "type": "string",
        "format": "",
        "title": "piId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "piStatus",
        "type": "string",
        "format": "",
        "title": "piStatus",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "recurring",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "sourceId",
        "additionalProperties": [],
        "description": "",
        "className": "Recurring",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "refund-request",
        "key": "amount",
        "type": "number",
        "format": "float",
        "title": "amount",
        "additionalProperties": [],
        "description": "",
        "className": "RefundRequest",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "refund-request",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "RefundRequest",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "refund-request",
        "key": "error",
        "type": "string",
        "format": "text",
        "title": "error",
        "additionalProperties": [],
        "description": "",
        "className": "RefundRequest",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "refund-request",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "RefundRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "refund-request",
        "key": "paypalTransaction",
        "type": "rel",
        "format": "paypal-transaction",
        "title": "paypalTransaction",
        "additionalProperties": [],
        "description": "",
        "className": "RefundRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "refund-request",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "RefundRequest",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "refund-request",
        "key": "transactionId",
        "type": "string",
        "format": "",
        "title": "transactionId",
        "additionalProperties": [],
        "description": "",
        "className": "RefundRequest",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "arguments",
        "type": "string",
        "format": "",
        "title": "Arguments",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "command",
        "type": "string",
        "format": "",
        "title": "Command",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "executeFinish",
        "type": "string",
        "format": "date-time",
        "title": "Execute Finish",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "executeStart",
        "type": "string",
        "format": "date-time",
        "title": "Execute Start",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "system-run",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "system-run",
        "key": "response",
        "type": "string",
        "format": "text",
        "title": "Response",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "system-run",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [],
        "description": "",
        "className": "SystemRun",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "authSourceId",
        "type": "integer",
        "format": "",
        "title": "authSourceId",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "webhook",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "webhook",
        "key": "data",
        "type": "array",
        "format": "string",
        "title": "data",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "webhook",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "webhook",
        "key": "processed",
        "type": "boolean",
        "format": "",
        "title": "processed",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "webhook",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "Webhook",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    }
]